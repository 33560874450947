import React, { useState } from 'react'
import Navbar from '../components/Navbar';
import axios from 'axios';
import SuccessModal from '../components/SuccessModal';
import AuthModal from '../components/AuthModal';

const MobileContact = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [successModal, setSuccessModal] = useState(false);
    const [existsModal, setExistsModal] = useState(false);
    const [emtpyModal, setEmptyModal] = useState(false);

    const toggleExistModal = () => {
        setExistsModal(prev => !prev);
    }

    const toogleSuccessModal = () => {
        setSuccessModal(prev => !prev);
    }

    const toggleEmptyModal = () => {
        setEmptyModal(prev => !prev);
    }

    const handleSubmit = async () => {
        if (phoneNumber !== "") {
            await axios.post("https://vektu.herokuapp.com/phoneContact",
                {
                    phoneNumber: phoneNumber,
                }).then((response) => {
                    if (response.data.message === "Username Already Exists!") {
                        console.log(response.data);
                        toggleExistModal();

                    } else if (phoneNumber.length < 11) {
                        toggleEmptyModal();
                    }
                    else if (response.data.message === "User Added") {
                        console.log(response.data);
                        console.log(phoneNumber.length);
                        toogleSuccessModal();
                    }

                });
        } else {
            toggleEmptyModal();
        }
    }

    const preventKeys = () => {
        document.getElementById('phoneNum').addEventListener('keydown', function (e) {
            if (e.which === 38 || e.which === 40) {
                e.preventDefault();
            }
        });
    }

    return (
        <>
            <Navbar />
            <div className='flex-col w-[80%] mr-[10%] ml-[10%] pt-14 '>
                <div className='pb-10' style={{ direction: 'rtl' }}>
                    <label htmlFor="phoneNumber" className='float-right font-AlmaraiBold text-xl'> رقم الهاتف</label>
                </div>
                <div className='pb-2 pt-5 mb-2 flex align-top' style={{ direction: 'rtl' }}>
                    <input id="phoneNum" value={Math.max(0, phoneNumber)} maxLength={11} minLength={11} type="number" min="0"
                        onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            preventKeys();
                        }}
                        className='ml-2 mt-[2px] p-2 rounded-full'
                        onChange={(event) => { setPhoneNumber(event.target.value) }} />
                </div>
                <div style={{ direction: 'rtl' }} className="pt-5">
                    <button className='bg-primary-blue rounded-full md:w-[10%] w-[30%] justify-center h-[35px] font-CairoMedium text-l text-white'
                        onClick={handleSubmit}>ارسال</button>
                </div>
            </div>
            <AuthModal modalOpen={emtpyModal} title={"برجاء ادخال رقم هاتف صحيح"}
                body={"برجاء ادخال رقم هاتف صحيح و عدم تركو خاليا, لكى نتمكن من التواصل معك"}
                toggleModal={toggleEmptyModal}>
            </AuthModal>
            <AuthModal modalOpen={existsModal} title={"هذا الرقم تم ادخالة من قبل"}
                body={"برجاء ادخال رقمك مرة وتحدة فقط, هذا الرقم مسجل لدينا بالفعل"}
                toggleModal={toggleExistModal}>
            </AuthModal>
            <SuccessModal title="تم ادخال الرقم بنجاح" modalOpen={successModal} toggleModal={toogleSuccessModal} body="تم ادخال الرقم بنجاح, سيتم التواصل معك قريبا">
            </SuccessModal>
        </>
    )
}

export default MobileContact