import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProtectedRoutes from './ProtectedRoutes';
import AuthModal from '../components/AuthModal';

const Auth = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [privilege, setPrivilege] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(prev => !prev)
    }

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate('/dashboard', { state: { privilege: privilege, state: isLoggedIn, username: username } });
        }
    }, [isLoggedIn])

    //Adding LoginState to LocalStorage
    useEffect(() => {
        const data = window.localStorage.getItem('VEKTU_SESSION');
        if (data !== null) {
            setIsLoggedIn(JSON.parse(data))
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem('VEKTU_SESSION', JSON.stringify(isLoggedIn))
    }, [isLoggedIn])

    //Adding PrivilegeState to LocalStorage
    useEffect(() => {
        const data = window.localStorage.getItem('VEKTU_PRIVILEGE');
        if (data !== null) {
            setPrivilege(JSON.parse(data))
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem('VEKTU_PRIVILEGE', JSON.stringify(privilege))
    }, [privilege])

    //Setting PrivilegeState
    const privilegeHandler = (myPrivilege) => {
        setPrivilege(myPrivilege);
    }

    //Setting LoggedInState
    const loginStateHandler = () => {
        setIsLoggedIn(!isLoggedIn);
    }

    const loginHandler = async (username, password) => {
        await axios.get("https://vektu.herokuapp.com/authLogin", {
            params: {
                username: username,
                password: password,
            }
        }).then((response) => {
            if (response.data.length === 0) {
                console.log("Username or password incorrect");
                openModal();
            } else if (response.data[0].username === username && response.data[0].password === password) {
                loginStateHandler();
                privilegeHandler(response.data[0].privilege);
            }
        });
    }

    return (
        <div>
            {!isLoggedIn ?
                <>
                    <h1 className='pt-10 text-center font-NeutralBold text-secondary-blue text-5xl pb-10 '>VEKTU</h1>
                    <div className='bg-primary-blue flex-row justify-center pt-16 pb-10 align-middle'>
                        <div>
                            <h1 className='text-primary-offwhite text-2xl font-NeutralBold text-center pb-5'
                            >Welcome to your dashbaord</h1>
                            <h4 className='text-primary-offwhite text-xl font-NeutralReg text-center  pb-10'>Please Login to continue</h4>
                        </div>
                        <div className='flex flex-col w-[85%] md:w-[60%] m-auto justify-center align-middle'>
                            <label className='font-NeutralBold text-2xl text-primary-offwhite block pb-5'
                                htmlFor="Username">Username</label>
                            <input className='block outline-none rounded-full pb-4 pt-4 px-4 font-AlmaraiBold text-sm'
                                type="text" placeholder='Username'
                                required
                                name='username'
                                onChange={(e) => setUsername(e.target.value)}
                                value={username} />
                        </div>
                        <div className='flex flex-col w-[85%] md:w-[60%] m-auto justify-center align-middle'>
                            <label className='font-NeutralBold text-2xl text-primary-offwhite block pt-10 pb-5'
                                htmlFor="Password">Password</label>
                            <input className='block outline-none rounded-full pb-4 pt-4 px-4 font-AlmaraiBold text-sm'
                                required
                                type="text" placeholder='Password'
                                name='password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={password} />
                        </div>
                        <div className='justify-center align-middle text-center flex md:pt-[70px] pt-10'>
                            <button onClick={() => loginHandler(username, password)}
                                className='bg-primary-offwhite rounded-full md:w-[20%] w-[40%] h-[50px]
                                text-xl font-NeutralBold text-primary-blue'>Login</button>
                        </div>
                    </div>
                    <div className='pb-10'>
                        <h1 className='text-lg text-black font-NeutralReg pl-8 pt-8'>Want to submit a survey?</h1>
                        <h1 className='text-3xl text-lack font-NeutralBold pl-8 pt-3'>Home</h1>
                    </div>
                    <AuthModal modalOpen={modalOpen} toggleModal={openModal} title={"Wrong Username Or Password"}
                        body={"Please double check your username and password and try again"}>
                    </AuthModal>
                </>
                : <ProtectedRoutes state={isLoggedIn} />
            }
        </div>
    )
}

export default Auth