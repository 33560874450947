import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ViewMobiles = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        retrieveMobiles();
    }, [])


    const retrieveMobiles = async () => {
        await axios.get("https://vektu.herokuapp.com/usersMobiles", {
        }).then((response) => {
            if (response.data.length === 0) {
                console.log("No Data Found!");
            } else {
                console.log(response);
                setUsers(response.data);
            }
        });
    }


    return (
        <>
            <h1 className='text-2xl font-NeutralBold pb-2 pl-3 pt-5'>Users Phones</h1>
            <div className='flex justify-center pb-10 pt-3'>
                <input className='rounded-full px-2 py-2 w-[90%] m-auto'
                    placeholder='Search'
                    type="text" name="" id="" />
            </div>
            {users.map((val) => {
                return <div className='bg-primary-blue w-[80%] pb-3 pt-3 m-auto mb-10
                font-NeutralReg text-center rounded-lg text-primary-offwhite'>
                    <button className='block m-auto'
                        value={val.phoneNumber}>{val.phoneNumber}
                    </button>
                </div>
            })}

        </>
    )
}

export default ViewMobiles