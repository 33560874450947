import React, { useState, useEffect } from 'react'
import Divider from './Divider'

const Step1 = ({ formData, setFormData, updateQuestionThree }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [checkedState, setCheckedState] = useState([{
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false
    }]);

    const [questionThree, setQuestionThree] = useState([
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked']);

    useEffect(() => {
        console.log(questionThree);
        updateQuestionThree(questionThree);
    }, [questionThree]);

    const preventKeys = () => {
        document.getElementById('phoneNum').addEventListener('keydown', function (e) {
            if (e.which === 38 || e.which === 40) {
                e.preventDefault();
            }
        });
    }

    return (
        < div className='flex-col w-[70%] mr-[15%] ml-[15%]' >
            {/*Question One*/}
            <div className='pb-10' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age">السن</label>
            </div>
            <div className='pb-2 mb-2 flex align-top' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[2px] flex-none' type="radio" value="تحت ١٨ سنة" name="questionOne"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionOne === "تحت ١٨ سنة"} />
                <label className='font-CairoSBold' htmlFor=""> تحت ١٨ سنة</label>
            </div>
            <div className='pb-2 mb-2 flex align-top' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[2px]' type="radio" value="١٨-٢٥ سنة" name="questionOne"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionOne === "١٨-٢٥ سنة"} />
                <label htmlFor="" className='font-CairoSBold'>١٨-٢٥ سنة</label>
            </div>
            <div className='pb-2 mb-2 flex align-top' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[2px]' type="radio" value="٢٦-٣٩ سنة" name="questionOne"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionOne === "٢٦-٣٩ سنة"} />
                <label htmlFor="" className='font-CairoSBold'>٢٦-٣٩ سنة</label>
            </div>
            <div className='pb-2 mb-2 flex align-top' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[2px]' type="radio" value="٤٠-٦٩ سنة" name="questionOne"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionOne === "٤٠-٦٩ سنة"} />
                <label htmlFor="" className='font-CairoSBold'>٤٠-٦٩ سنة</label>
            </div>
            <div className='ml-2 mb-2 flex align-top' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[2px]' type="radio" value="اكثر من ٧٠ سنة" name="questionOne"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionOne === "اكثر من ٧٠ سنة"} />
                <label htmlFor="" className='font-CairoSBold'> اكثر من ٧٠ سنة</label>
            </div>
            <Divider />
            {/*Question Two*/}
            <div className='pb-24' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age">ما هو رقم الهاتف الذي يمكننا التواصل معك من خلاله؟ (الرجاء استخدام رقم هاتف مسجل على تطبيق واتساب ليتمكن فريقنا من التواصل معك)
                </label>
            </div>
            <div className='pt-24 md:pt-10 pb-10'>
                <input id="phoneNum" value={Math.max(0, formData.questionTwo)} maxLength={11} minLength={11} type="number" min="0"
                    onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        preventKeys();
                    }}
                    name='questionTwo' onChange={(event) => { setFormData({ ...formData, [event.target.name]: event.target.value }) }} className='float-right w-[90%] font-CairoRegular text-center rounded-md resize-none' placeholder='اجب هنا' />
            </div>
            <Divider />
            {/*Question Three*/}
            <div className='pb-24' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age"> ما هو السبب الرئيسي لزيارة موقع فيكتو اليوم؟ (يمكنك اختيار اكثر من اجابة)
                </label>
            </div>
            <div className='mb-2 flex items-start border' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اعاني في الاداء الجنسي منذ فترة و استخدمت بعض الوصفات الطبية و لا اشعر بتحسن"
                    type="checkbox"
                    checked={checkedState[0].q1}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q1 === false) {
                                const newArray = [...questionThree];
                                newArray[0] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q1: true };
                            } else if (obj.q1 === true) {
                                const newArray = [...questionThree];
                                newArray[0] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q1: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اعاني في الاداء الجنسي منذ فترة و استخدمت بعض الوصفات الطبية و لا اشعر بتحسن
                </label>
            </div>
            <div className='mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اشعر ان ادائي الجنسي قد انخفض مؤخرا و لم اتلقى اي خدمات او وصفات طبية تخص هذا الامر بعد"
                    type="checkbox"
                    checked={checkedState[0].q2}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q2 === false) {
                                const newArray = [...questionThree];
                                newArray[1] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q2: true };
                            } else if (obj.q2 === true) {
                                const newArray = [...questionThree];
                                newArray[1] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q2: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر ان ادائي الجنسي قد انخفض مؤخرا و لم اتلقى اي خدمات او وصفات طبية تخص هذا الامر بعد
                </label>
            </div>
            <div className='mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اشعر اني غير قادر على امتاع شريكي اثناء العلاقة الجنسية"
                    type="checkbox"
                    checked={checkedState[0].q3}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q3 === false) {
                                const newArray = [...questionThree];
                                newArray[2] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q3: true };
                            } else if (obj.q3 === true) {
                                const newArray = [...questionThree];
                                newArray[2] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q3: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر اني غير قادر على امتاع شريكي اثناء العلاقة الجنسية
                </label>
            </div>
            <div className='mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اشعر اني غير قادر على امتاع شريكي اثناء العلاقة الجنسية"
                    type="checkbox"
                    checked={checkedState[0].q4}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q4 === false) {
                                const newArray = [...questionThree];
                                newArray[3] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q4: true };
                            } else if (obj.q4 === true) {
                                const newArray = [...questionThree];
                                newArray[3] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q4: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر ان هناك مشكلة ما اثناء العلاقة الجنسية و لكني لا اعلم السبب
                </label>
            </div>
            <div className='mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اود ان اقوم بشراء الوصفات الطبية الخاصة بتحسين الاداء الجنسي"
                    type="checkbox"
                    checked={checkedState[0].q5}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q5 === false) {
                                const newArray = [...questionThree];
                                newArray[4] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q5: true };
                            } else if (obj.q5 === true) {
                                const newArray = [...questionThree];
                                newArray[4] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q5: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اود ان اقوم بشراء الوصفات الطبية الخاصة بتحسين الاداء الجنسي
                </label>
            </div>
            <div className='mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none'
                    name="questionThree"
                    value="اخرى"
                    type="checkbox"
                    checked={checkedState[0].q6}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q6 === false) {
                                const newArray = [...questionThree];
                                newArray[5] = event.target.value;
                                setQuestionThree(newArray);
                                return { ...obj, q6: true };
                            } else if (obj.q6 === true) {
                                const newArray = [...questionThree];
                                newArray[5] = "Unchecked";
                                setQuestionThree(newArray);
                                return { ...obj, q6: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اخرى
                </label>
            </div>
            {/*Question Four*/}
            <Divider />
            <div className='pb-24' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age">كم مرة في الاسبوع تقريبا تشعر بانتصاب عند الاستيقاظ من النوم؟
                </label>
            </div>
            <div className='pb-2 mb-2 flex items-start border' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لم اشعر بانتصاب عند الاستيقاظ من النوم منذ فترة" name="questionFour"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionFour === "لم اشعر بانتصاب عند الاستيقاظ من النوم منذ فترة"} />
                <label className='font-CairoSBold' htmlFor=""> لم اشعر بانتصاب عند الاستيقاظ من النوم منذ فترة
                </label>
            </div>
            <div className='pb-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة واحدة في الاسبوع" name="questionFour"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFour === "مرة واحدة في الاسبوع"} />
                <label htmlFor="" className='font-CairoSBold'> مرة واحدة في الاسبوع
                </label>
            </div>
            <div className='pb-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرتين في الاسبوع" name="questionFour"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFour === "مرتين في الاسبوع"} />
                <label htmlFor="" className='font-CairoSBold'> مرتين في الاسبوع
                </label>
            </div>
            <div className='pb-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="ثلاثة مرات في الاسبوع او اكثر" name="questionFour"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFour === "ثلاثة مرات في الاسبوع او اكثر"} />
                <label htmlFor="" className='font-CairoSBold'> ثلاثة مرات في الاسبوع او اكثر
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لا اعرف حيث لا اهتم بملاحظة هذا الامر عند الاستيقاظ (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم)" name="questionFour"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFour === "لا اعرف حيث لا اهتم بملاحظة هذا الامر عند الاستيقاظ (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم)"} />
                <label htmlFor="" className='font-CairoSBold'> لا اعرف حيث لا اهتم بملاحظة هذا الامر عند الاستيقاظ (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم)
                </label>
            </div>
            {/*Question Five*/}
            <Divider />
            <div className='pb-48 md:pb-24 pt-10' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age">كم مرة في اليوم تقريبا تشعر بانتصاب؟ (الرجاء ان تتضمن عدد المرات اثناء اداء العمل او الروتين اليومي و ليس اثناء التعرض الى او مشاهدة اي محتوى/ موقف اغرائي او اباحي)
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة واحدة في اليوم" name="questionFive"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFive === "مرة واحدة في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'> مرة واحدة في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="٢-٥ مرات في اليوم" name="questionFive"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFive === "٢-٥ مرات في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'> ٢-٥ مرات في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اكثر من ٥ مرات في اليوم" name="questionFive"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFive === "اكثر من ٥ مرات في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'> اكثر من ٥ مرات في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='mt-[5px] ml-2 flex-none' type="radio" value="لا اعرف حيث لا اهتم بملاحظة هذا الامر (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم" name="questionFive" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }}
                    checked={formData.questionFive === "لا اعرف حيث لا اهتم بملاحظة هذا الامر (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم"}
                />
                <label htmlFor="" className='font-CairoSBold'> لا اعرف حيث لا اهتم بملاحظة هذا الامر (نرجو منك ملاحظة هذا الامر خلال الاسبوع القادم)
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start pt-4' style={{ direction: 'rtl' }}>
                <input className=' ml-2 mt-[5px] flex-none'
                    type="radio"
                    value="لم اشعر بانتصاب اثناء الروتين اليومي منذ فترة" name="questionFive"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFive === "لم اشعر بانتصاب اثناء الروتين اليومي منذ فترة"} />
                <label className='font-CairoSBold' htmlFor=""> لم اشعر بانتصاب اثناء الروتين اليومي منذ فترة
                </label>
            </div>
        </div >
    )
}

export default Step1