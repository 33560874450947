import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import './index.css';
import Auth from './views/Auth';
import Dashboard from './views/Dashboard';
import Form from './views/Form';
import Landing from './views/Landing';
import ManageUsers from './views/ManageUsers';
import SurveyAnswers from './views/SurveyAnswers';
import ProtectedRoutes from './views/ProtectedRoutes';
import ViewUsers from './views/ViewUsers';
import MobileContact from './views/MobileContact';
import ViewMobiles from './views/ViewMobiles';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='survey' element={<Form />} />
      <Route path='auth' element={<Auth />} />
      <Route path='phone' element={<MobileContact />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='viewusers' element={<ViewUsers />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='surveyanswers' element={<SurveyAnswers />} />
        <Route path='manageusers' element={<ManageUsers />} />
        <Route path='viewmobiles' element={<ViewMobiles />} />
      </Route>
    </Routes>
  </BrowserRouter >
);
