import React, { useState } from 'react'
import Hamburger from '../assets/img/hamburger.svg'
import Close from '../assets/img/close.svg'
import Logo from '../assets/img/logo.svg'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    return (
        <nav className='w-full flex justify-between align-middle bg-primary-offwhite px-4 py-8'>
            <div className='flex items-start align-middle text-secondary-blue text-3xl font-AlmaraiEXBold '>
                <Link to="/"> <img className='w-[20%]' src={Logo} alt="" /> </Link>
            </div>
            <ul className='justify-center list-none align-middle flex-1 md:flex hidden'>
                <a className='font-AlmaraiBold text-2xl text-secondary-blue px-6 cursor-pointer' href="#home">الأساسية </a>
                <a className='font-AlmaraiBold text-2xl text-secondary-blue px-6 cursor-pointer' href="#contact">تواصل</a>
                <a className='font-AlmaraiBold text-2xl text-secondary-blue px-6 cursor-pointer' href="#why">لماذا نحن</a>
            </ul>
            <div className="justify-end align-middle hidden md:flex">
                <button className='rounded-full bg-secondary-blue w-[150px] h-[45px] text-primary-offwhite font-CairoEXBold'><a href="/#contact">تواصل معنا</a> </button>
            </div>
            <div className="flex md:hidden ">
                <span color='#FFF' fontSize={27} onClick={() => setToggleMenu(true)} > <img src={Hamburger} alt="" /></span>
                {toggleMenu && (
                    <div className='fixed top-0  left-0 w-full h-[100vh] bg-primary-offwhite flex-col z-10  slide-bottom'>
                        <span fontSize={27} className="absolute top-[20px] right-[20px] text-xl " onClick={() => setToggleMenu(false)}  > <img src={Close} alt="" /> </span>
                        <ul className='list-none pt-44 justify-center align-middle text-center'>
                            <li className='mb-5'>
                                <a onClick={() => setToggleMenu(false)} className='font-AlmaraiBold text-secondary-blue mx-8 my-8 cursor-pointer text-center text-3xl' href="/#home">الأساسية</a>

                            </li>
                            <li className='mb-5'>
                                <a onClick={() => setToggleMenu(false)} className='font-AlmaraiBold text-secondary-blue mx-8 my-8 cursor-pointer text-center text-3xl' href="/#contact">تواصل</a>
                            </li>
                            <li>
                                <a onClick={() => setToggleMenu(false)} className='font-AlmaraiBold text-secondary-blue mx-8 my-8 cursor-pointer text-center text-3xl' href="/#why">لماذا نحن</a>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </nav >
    )
}

export default Navbar