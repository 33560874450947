import React from 'react'
import Help from '../assets/img/help.svg'
import Close from '../assets/img/close.svg'
import { Link } from "react-router-dom";

const Modal = ({ modalOpen, toggleModal }) => {
    return (
        <>
            {modalOpen ?
                <div id="background" className='w-full h-[100%] bg-black/50 fixed inset-0 flex justify-center align-middle'>
                    <div onClick={toggleModal} id='Wrapper' className='lg:w-[50%] md:w-[60%] w-[80%] rounded-xl  h-[62%] md:h-[70%] lg:h-[75%] bg-[#EAEBED] text-[#000] mt-20 relative z-10'>
                        <div className='float-right px-4 py-4 fixed' ><img className='right' src={Close} alt="" /></div>
                        <div id='ModalContent' className='flex-col justify-center align-middle '>
                            <h1 className='text-center text-primary-blue font-AlmaraiEXBold md:text-2xl lg:text-3xl text-lg mt-14'>اختار الاختيار المناسب لك</h1>
                            <img className='flex justify-center align-middle text-center m-auto pt-10 w-[30%]' src={Help} alt="" />
                            <h1 className='text-center font-CairoRegular md:text-lg text-sm lg:text-xl w-[60%] m-auto mt-5 mb-2'>بإمكانك الاختيار بين إكمال استيبان سريع, او
                                الاتصال بأحد اطبائنا المتخصصين
                            </h1>
                            <div className='flex justify-center mt-14'>
                                <Link className='bg-primary-blue rounded-full mr-5 w-[45%] text-sm lg:text-base lg:w-[30%] h-[50px] text-[#EAEBED] font-CairoBold flex justify-center ' to='/survey'>
                                    <button className='text-center' >اكمل الاستبيان</button>
                                </Link>
                                <Link to='/phone'>
                                    <button className='text-sm lg:text-base font-CairoBold underline w-[109%] lg:w-[100%] pt-[20%]' >الاتصال هاتفيا</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}

export default Modal