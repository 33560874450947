import React from 'react'
import Mail from '../assets/img/mail.svg'
import WhatsappBlack from '../assets/img/whatsappBlack.svg'


const Contact = () => {
    return (
        <div id="contact" className='bg-primary-offwhite pt-10 mb-10'>
            <h1 className='font-AlmaraiBold text-black text-2xl text-center mr-[10%] ml-[10%] w-[80%]'>هل لديك اية اسئلة او تريد ان تعلم المزيد؟
                تواصل معنا الان </h1>
            <div className='flex w-[90%] ml-[5%] mr-[5%] pt-10 justify-center align-middle'>
                <div className='flex mr-1 md:pr-10 pl-5'>
                    <div className='pr-1'>
                        <h1 style={{ direction: "rtl" }} className="font-CairoRegular text-sm md:text-2xl">عبر البريد الاكترونى</h1>
                        <h1 style={{ direction: "rtl" }} className="text-sm md:text-2xl">info@vektu.com</h1>
                    </div>
                    <div>
                        <img className=' w-[60%] p-0 md:w-[35%] ml-4 ' src={Mail} alt="" />
                    </div>
                </div>
                <div className='flex ml-1 md:pl-10 '>
                    <div className=''>
                        <h1 style={{ direction: "rtl" }} className="font-CairoRegular text-sm md:text-2xl">عبر الواتساب</h1>
                        <h1 style={{ direction: "rtl" }} className="text-sm md:text-2xl">01021668690</h1>
                    </div>
                    <div>
                        <img className=' w-[60%] md:w-[37%] md:ml-5 ml-4' src={WhatsappBlack} alt="" />
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}

export default Contact