import React from 'react'

export const StepInverted = (props) => {
    return (
        <div className='flex w-[80%] ml-[10%] mr-[10%] mb-[15%] justify-center align-middle'>
            <div className='flex-row pr-[15px]'>
                <h1 style={{ direction: "rtl" }} className="font-AlmaraiEXBold text-primary-offwhite text-2xl mb-3">{props.title}</h1>
                <h2 style={{ direction: "rtl" }} className="text-primary-offwhite text-base pl-3 font-CairoMedium">{props.body} </h2>
            </div>
            <div className='mt-5 flex'>
                <div className='rounded-full flex justify-center bg-primary-offwhite w-[80px] h-[80px]'>
                    <img className='w-[60%]' src={props.icon} alt="" />
                </div>
            </div>
        </div >
    )
}

export default StepInverted