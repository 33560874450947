import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import SuccessModal from '../components/SuccessModal';
import AuthModal from '../components/AuthModal';

const ManageUsers = () => {
    const mystate = useLocation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [privilege, setPrivilege] = useState("admin");
    const [auth] = useState(mystate.state.privilege);
    const [modalOpen, setModalOpen] = useState(false);
    const [failModalOpen, setFailModelOpen] = useState(false);
    const [failModalEmpty, setFailModelEmpty] = useState(false);

    const openModal = () => {
        setModalOpen(prev => !prev)
    }

    const openFailModal = () => {
        setFailModelOpen(prev => !prev);
    }

    const openFailModelEmpty = () => {
        setFailModelEmpty(prev => !prev);
    }

    const handleSubmit = async () => {
        if (username !== "" && password !== "") {
            await axios.post("https://vektu.herokuapp.com/authRegister",
                {
                    username: username,
                    password: password,
                    privilege: privilege
                }).then((response) => {
                    if (response.data.message === "Username Already Exists!") {
                        openFailModal()
                    } else if (response.data.message === "User Added") {
                        console.log(response.data)
                        openModal();
                    }
                });
        } else {
            openFailModelEmpty();
        }
    }

    return (
        <div>
            <h1 className='text-2xl font-NeutralBold pl-3 pt-4 pb-5'>Add a new users !</h1>
            <div className='bg-primary-blue h-[100vh]'>
                <h1 className='text-base font-NeutralReg pt-8  md:pt-10 md:pb-10 pb-8 w-[85%] md:w-[60%] m-auto justify-center align-middle
                 pl-3 md:pl-0 text-primary-offwhite'>
                    You can add new admins or
                    doctors to the system here
                </h1>
                <div className='flex flex-col w-[85%] md:w-[60%] m-auto justify-center pt-8 md:pt-0 align-middle'>
                    <label className='font-NeutralBold text-lg text-primary-offwhite block pb-6'
                        htmlFor="Username"> Username</label>
                    <input className='block outline-none rounded-full pb-4 pt-4 px-4 font-AlmaraiBold text-sm'
                        type="text" name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className='flex flex-col w-[85%] md:w-[60%] m-auto justify-center align-middle md:mt-8 mt-12'>
                    <label className='font-NeutralBold text-lg text-primary-offwhite block pb-6'
                        htmlFor="Password"> Password</label>
                    <input className='block rounded-full pb-4 pt-4 px-4 outline-none font-AlmaraiBold text-sm'
                        type="text" name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)} />
                </div>
                {auth === "admin" ?
                    <div className='flex flex-col w-[85%] md:w-[60%] m-auto justify-center align-middle md:mt-8 mt-12'>
                        <label className='font-NeutralBold text-lg text-primary-offwhite block pb-6'
                            htmlFor="privilege"> Privilege</label>
                        <select className='block rounded-full outline-none pb-4 pt-4 px-4 font-NeutralReg text-sm
                        border-r-[15px] border-transparent'
                            value={privilege} onChange={e => setPrivilege(e.target.value)}>
                            <option>admin</option>
                            <option>doctor</option>
                        </select>
                    </div>
                    :
                    <>
                        <> </>
                    </>
                }
                <div className='justify-center align-middle text-center flex md:pt-[70px] pt-20' >
                    <button className='bg-primary-offwhite rounded-full md:w-[20%] w-[40%] h-[50px]
                                text-xl font-NeutralBold text-primary-blue'
                        onClick={handleSubmit}>Add User</button>
                </div>
            </div>
            <AuthModal modalOpen={failModalOpen} title={"Username Already Exists!"}
                body={"This Username Already Exists, try another one"} toggleModal={openFailModal}>
            </AuthModal>
            <AuthModal modalOpen={failModalEmpty} title={"Empty Username or password Feild"}
                body={"Please add both a username and password values to create a new user"}
                toggleModal={openFailModelEmpty}>
            </AuthModal>
            <SuccessModal title="User Added Successfully" modalOpen={modalOpen} toggleModal={openModal} body="This new user has been added to the database, you can now use it to manage your app">
            </SuccessModal>
        </div>
    )
}

export default ManageUsers