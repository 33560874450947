import React from 'react'
import Confirmation from '../assets/img/confirmation.svg'
import { useNavigate } from "react-router-dom";

const ConfirmationModal = ({ modalOpen, setModalOpen }) => {
    let navigate = useNavigate();

    return (
        <>
            {modalOpen ?
                <div onClick={setModalOpen} id="background" className='w-full h-[100%] bg-black/50 fixed inset-0 flex justify-center align-middle'>
                    <div id='Wrapper' className='lg:w-[50%] md:w-[60%] w-[80%] rounded-xl  h-[55%] md:h-[63%] lg:h-[67%] bg-[#EAEBED] text-[#252525] mt-20 relative z-10'>
                        <div id='ModalContent' className='flex-col justify-center align-middle '>
                            <h1 className='text-center text-primary-blue font-AlmaraiEXBold md:text-2xl lg:text-3xl text-lg mt-10'>تم الانتهاء من الاستبيان بنجاح</h1>
                            <img className='flex justify-center align-middle text-center m-auto pt-10 w-[30%]' src={Confirmation} alt="" />
                            <h1 className='text-center font-CairoRegular md:text-lg text-sm lg:text-xl w-[60%] m-auto mt-5 mb-2'>سوف يتواصل معك احد خبرائنا عن طريق
                                الواتساب فى خلال اليومين القادمين
                            </h1>
                            <button className='pt-2 mt-6 flex align-middle text-center m-auto bg-primary-blue rounded-full md:w-[35%] w-[40%] justify-center h-[45px] font-CairoMedium text-xl text-white' onClick={window.onpopstate = () => {
                                navigate("/");
                            }}>Confirm</button>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}

export default ConfirmationModal