import React, { useState } from 'react'
import axios from 'axios';
import Step1 from '../components/Step1'
import Step2 from '../components/Step2'
import Step3 from '../components/Step3'
import NavBar from '../components/Navbar'
import ConfirmationModal from '../components/ConfirmationModal'
import AuthModal from '../components/AuthModal';


const Form = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [failModalOpen, setFailModelOpen] = useState(false);
    const [consentModalOpen, setConsentModalOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [consent, setConsent] = useState(false);
    const [consent2, setConsent2] = useState(false);
    const [formData, setFormData] = useState({
        questionOne: "",
        questionTwo: "",
        questionThree: [
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked"],
        questionFour: "",
        questionFive: "",
        questionSix: "",
        questionSeven: "",
        questionEight: "",
        questionNine: "",
        questionTen: [
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked"],
        questionEleven: [
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked",
            "unchecked"],
        questionTwelve: "",
        questionThirteen: "",
        questionFourteen: "",
    });

    const openFailModal = () => {
        setFailModelOpen(prev => !prev);
    }

    const openConsentModal = () => {
        setConsentModalOpen(prev => !prev);
    }

    const PageDisplay = () => {
        if (step === 0) {
            return <Step1 formData={formData} updateQuestionThree={updateQuestionThree} setFormData={setFormData} />;
        } else if (step === 1) {
            return <Step2 formData={formData} updateQuestionTen={updateQuestionTen} setFormData={setFormData} />;
        } else {
            return <Step3 formData={formData} updateQuestionEleven={updateQuestionEleven} consent={consent}
                setConsent={setConsent} setFormData={setFormData} consent2={consent2} setConsent2={setConsent2} />;
        }
    }

    const ShowConfirmationModal = () => {
        if (formData.questionOne === "" || formData.questionTwo === "" ||
            formData.questionThree === [
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked"] ||
            formData.questionFour === "" || formData.questionFive === "" || formData.questionSix === "" || formData.questionSeven === "" || formData.questionEight === "" || formData.questionNine === "" ||
            formData.questionTen === [
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked"] ||
            formData.questionEleven === [
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked",
                "unchecked"] || formData.questionTwelve === "" || formData.questionThirteen === "" || formData.questionFourteen === "") {
            openFailModal();
        } else if (consent === false || consent2 === false) {
            openConsentModal();
        }
        else {
            setModalOpen(prev => !prev)
            axios.post("https://vektu.herokuapp.com/insert",
                {
                    questionOne: formData.questionOne,
                    questionTwo: formData.questionTwo,
                    questionThree: formData.questionThree,
                    questionFour: formData.questionFour,
                    questionFive: formData.questionFive,
                    questionSix: formData.questionSix,
                    questionSeven: formData.questionSeven,
                    questionEight: formData.questionEight,
                    questionNine: formData.questionNine,
                    questionTen: formData.questionTen,
                    questionEleven: formData.questionEleven,
                    questionTwelve: formData.questionTwelve,
                    questionThirteen: formData.questionThirteen,
                    questionFourteen: formData.questionFourteen
                });
        }
    }

    const updateQuestionThree = (state) => {
        console.log(formData.questionThree);
        setFormData({ ...formData, questionThree: state })
    }

    const updateQuestionTen = (state) => {
        setFormData({ ...formData, questionTen: state });
    }

    const updateQuestionEleven = (state) => {
        setFormData({ ...formData, questionEleven: state });
    }

    return (
        <div >
            <NavBar />
            <AuthModal title={"نرجو منك قبول سياسات الخصوصية"} body={"نرجو منك قبول سياسات الخصوصية و المعلومات الخاصة بنا لنتمكن من استكمال الخدمة"} toggleModal={openConsentModal} modalOpen={consentModalOpen}></AuthModal>
            <AuthModal title={"الرجاء التأكد من الاجابة على جميع الاسئلة"} body={"الرجاء التأكد من الاجابة على جميع اسئلة الاستبيان حتى يمكنك المواصلة"} toggleModal={openFailModal} modalOpen={failModalOpen} />
            <div>
                <div className='w-[70%] mr-[15%] ml-[15%] text-center font-CairoMedium text-lg mb-10'>سنطرح عليك بعض الأسئلة الموجزة حول صحتك العامة و صحتك الجنسية من أجل تقييم الخدمة الطبية المناسبة لك. الرجاء العلم ان المعلومات المقدمة في هذا الاستبيان سرية للغاية و تستخدم لاغراض التشخيص و تحديد الخطة العلاجية فقط
                </div>
                <div className='h-[7px] mb-10 rounded-full m-auto mt-8 w-[70%] bg-[#D9D9D9]'>
                    <div className='h-[7px] mb-10 rounded-full  w-[70%]' style={{ width: step === 0 ? "33.3%" : step === 1 ? "66.6%" : "100%", backgroundColor: "#5478A0" }}>
                    </div>
                </div>
                <div >{PageDisplay()}
                </div>
                <div className='flex justify-end w-[70%] m-auto pt-20 mb-20'>
                    <div className={` ${step === 0 ? 'hidden' : 'flex'}  bg-secondary-blue mr-4 rounded-full md:w-[20%] w-[40%] justify-center h-[45px] font-CairoMedium text-xl text-white`}>
                        <button hidden={step === 0}
                            onClick={() => { setStep((currStep) => currStep - 1) }}>  السابق</button>
                    </div>
                    <div className={`flex ${step === 2 ? 'bg-[#1BCB91]' : 'bg-primary-blue'} rounded-full md:w-[20%] w-[40%] justify-center h-[45px] font-CairoMedium text-xl text-white`}>
                        <button onClick={() => { step === 2 ? ShowConfirmationModal() : setStep((currStep) => currStep + 1) }}> {step === 2 ? 'انهاء' : 'التالى'}</button>
                    </div>
                </div>
            </div>
            <ConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div >
    )
}

export default Form