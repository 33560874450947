import React from 'react'
import Error from '../assets/img/error.svg'
import Close from '../assets/img/close.svg'

const AuthModal = ({ modalOpen, toggleModal, title, body }) => {
    return (
        <>
            {modalOpen ?
                <div id="background" className='w-full h-[100%] bg-black/50 fixed inset-0 flex justify-center align-middle'>
                    <div onClick={toggleModal} id='Wrapper' className='lg:w-[50%] md:w-[60%] w-[80%] rounded-xl  h-[57%] md:h-[60%] lg:h-[70%] bg-[#EAEBED] text-[#000] mt-20 relative z-10'>
                        <div className='float-right px-4 py-4 fixed' ><img className='right' src={Close} alt="" /></div>
                        <div id='ModalContent' className='flex-col justify-center align-middle '>
                            <h1 className='text-center text-primary-blue font-AlmaraiEXBold md:text-2xl lg:text-3xl text-lg mt-14'>{title}</h1>
                            <img className='flex justify-center align-middle text-center m-auto pt-10 w-[30%]' src={Error} alt="" />
                            <h1 className='text-center font-CairoRegular md:text-lg text-sm lg:text-xl w-[60%] m-auto mt-5 mb-2'>{body}
                            </h1>
                        </div>
                    </div>
                </div>
                : null}
        </>
    )
}

export default AuthModal