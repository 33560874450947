import React, { useEffect } from 'react'
import Contact from '../components/Contact'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Why from '../components/Why'

const Landing = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Navbar />
            <Hero />
            <Why />
            <Contact />
        </div>
    )
}

export default Landing