import React from 'react'
import Step from './Step'
import StepInverted from './StepInverted'
import Survey from '../assets/img/survey.svg'
import Whatsapp from '../assets/img/whatsapp.svg'
import Truck from '../assets/img/truck.svg'
import Medical from '../assets/img/medical.svg'
import SurveyAlt from '../assets/img/surveyAlt.svg'
import WhatsappAlt from '../assets/img/whatsappAlt.svg'
import TruckAlt from '../assets/img/truckAlt.svg'
import MedicalAlt from '../assets/img/medicalAlt.svg'
import Arrow from '../assets/img/arrow.svg'
import DesktopStep from './DesktopStep'
import Modal from './Modal';
import { useEffect, useState } from 'react';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Why = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const openModal = () => {
        setModalOpen(prev => !prev)
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div id="why" className='bg-primary-blue flex-row w-full pb-10 mt-10'>
            <h1 style={{ direction: "rtl" }} className='font-AlmaraiEXBold text-4xl mb-4 pt-10 text-primary-offwhite text-center'>
                لماذا نحن؟
            </h1>
            <h2 style={{ direction: "rtl" }} className='font-CairoMedium pt-3 pb-16 text-primary-offwhite text-xl text-center w-[70%] ml-[15%] mr-[15%] mt-7' >
                السرية و الفعالية هما شعارنا, نحن نقوم بدور حلقة الوصل
                بينك و بين احد افضل الاطباء فى هذا المجال و نوفر لك
                خطة علاجية كاملة فى 4 خطوات فقط
            </h2>
            <div>
                {windowSize.innerWidth < 1024 ? <div>
                    <Step title="الخطوة الاولى"
                        body={"اجب على القليل من الاسئلة التى من خلالها سوف يقوم الطبيب بتشخيص حالتك"}
                        icon={Survey}></Step>
                    <StepInverted title={"الخطوة الثانية"}
                        body={"يتم التواصل معك لتنسيق الفحوصات الطبيه و اتمامها فى سريه تامة"}
                        icon={Medical}></StepInverted>
                    <Step title={"الخطوة الثالثة"}
                        body={"يتم التواصل معك عبر الواتساب لاخبارك بالتشخيص و الخطة العلاجية"}
                        icon={Whatsapp}></Step>
                    <StepInverted title={"الخطوة الرابعة"}
                        body={"استلم الأدوية و الخطة العلاجية من مندوبنا فى المكان الذي يناسبك"}
                        icon={Truck}></StepInverted>
                    <div className='mt-20 mb-10 flex justify-center'>
                        <button onClick={openModal}
                            className="bg-primary-offwhite lg:w-[70%] lg:float-right w-[70%] h-16 rounded-full text-primary-blue font-CairoBlack outline-none">تواصل مع أحد خبرائنا الآن</button>
                    </div>
                </div>
                    : <div>
                        <DesktopStep img={SurveyAlt} title={"الخطوة الاولى"} body={"اجب على القليل من الاسئلة التى من خلالها سوف يقوم الطبيب بتشخيص حالتك"} />
                        <img className='block ml-auto mr-auto w-[80px] pt-5 pb-5' src={Arrow} alt="Arrow" />
                        <DesktopStep img={MedicalAlt} title={"الخطوة الثانية"} body={"يتم التواصل معك لتنسيق الفحوصات الطبيه و اتمامها فى سريه تامة"} />
                        <img className='block ml-auto mr-auto w-[80px] pt-5 pb-5' src={Arrow} alt="Arrow" />
                        <DesktopStep img={WhatsappAlt} title={"الخطوة الثالثة"} body={"يتم التواصل معك عبر الواتساب لاخبارك بالتشخيص و الخطة العلاجية"} />
                        <img className='block ml-auto mr-auto w-[80px] pt-5 pb-5' src={Arrow} alt="Arrow" />
                        <DesktopStep img={TruckAlt} title={"الخطوة الرابعة"} body={"استلم الأدوية و الخطة العلاجية من مندوبنا فى المكان الذي يناسبك"} />
                        <div className='mt-20 mb-10 flex justify-center'>
                            <button onClick={openModal}
                                className="bg-primary-offwhite lg:w-[70%] lg:float-right w-[70%] h-16 rounded-full text-primary-blue font-CairoBlack outline-none">تواصل مع أحد خبرائنا الآن</button>
                        </div>
                    </div>}
                <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} />
            </div>
        </div>
    )
}

export default Why