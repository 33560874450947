import React, { useState, useEffect } from 'react'
import Divider from './Divider'

const Step2 = ({ formData, setFormData, updateQuestionTen }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [checkedState, setCheckedState] = useState([{
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false
    }]);

    const [questionTen, setQuestionTen] = useState([
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked']);

    useEffect(() => {
        updateQuestionTen(questionTen);
    }, [questionTen]);

    return (
        <div className='flex-col w-[70%] mr-[15%] ml-[15%]'>
            {/*Question Six*/}
            <div className='pb-28 md:pb-16 ' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > هل تتطلع لممارسة العلاقة الجنسية مع شريكتك و تستمتع بهذه العلاقة؟
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="نعم" name="questionSix"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionSix === "نعم"} />
                <label className='font-CairoSBold' htmlFor=""> نعم</label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لا" name="questionSix"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionSix === "لا"} />
                <label htmlFor="" className='font-CairoSBold'> لا</label>
            </div>
            <Divider />
            {/*Question Seven*/}
            <div className='pb-28 md:pb-16' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl pb-5' htmlFor=""> متى كانت المرة الاخيرة التي شعرت فيها بانتصاب يرضيك دون استخدام اي منشطات او ادوية؟
                </label>
            </div>
            <div className='pb-14 md:pb-10 pt-10' >
                <input onChange={(event) => {
                    const value = event.target.value
                    setFormData({ ...formData, [event.target.name]: value })
                }} className='float-right h-[40px] px-[10px] w-[200px] flex-none' type="date" name="questionSeven" />
            </div>
            <Divider />
            {/*Question Eight*/}
            <div className='pb-20 md:pb-16' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > هل تشعر بألم اثناء الانتصاب او العلاقة الجنسية او التبول؟
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لا اشعر بألم في اي من هذه الحالات" name="questionEight"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionEight === "لا اشعر بألم في اي من هذه الحالات"} />
                <label className='font-CairoSBold' htmlFor=""> لا اشعر بألم في اي من هذه الحالات
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اشعر بألم اثناء الانتصاب/ العلاقة الجنسية" name="questionEight"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionEight === "اشعر بألم اثناء الانتصاب/ العلاقة الجنسية"} />
                <label htmlFor="" className='font-CairoSBold'> اشعر بألم اثناء الانتصاب/ العلاقة الجنسية
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اشعر بألم اثناء التبول" name="questionEight"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionEight === "اشعر بألم اثناء التبول"} />
                <label htmlFor="" className='font-CairoSBold'> اشعر بألم اثناء التبول
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اشعر بألم اثناء الانتصاب و العلاقة الجنسية و التبول" name="questionEight"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionEight === "اشعر بألم اثناء الانتصاب و العلاقة الجنسية و التبول"} />
                <label htmlFor="" className='font-CairoSBold'> اشعر بألم اثناء الانتصاب و العلاقة الجنسية و التبول
                </label>
            </div>
            <Divider />
            {/*Question Nine*/}
            <div className='pb-20 md:pb-16' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > كم مرة تقريبا تشاهد/ تستمع الى اي محتوى/ افلام إباحية؟
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="١-٣ مرات في الاسبوع" name="questionNine"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionNine === "١-٣ مرات في الاسبوع"} />
                <label className='font-CairoSBold' htmlFor=""> ١-٣ مرات في الاسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة واحدة في اليوم" name="questionNine"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionNine === "مرة واحدة في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'> مرة واحدة في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اكثر من مرة في اليوم" name="questionNine"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionNine === "اكثر من مرة في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'> اكثر من مرة في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لا اشاهد/ استمع الى اي محتوى/ افلام اباحية" name="questionNine"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionNine === "لا اشاهد/ استمع الى اي محتوى/ افلام اباحية"} />
                <label htmlFor="" className='font-CairoSBold'> لا اشاهد/ استمع الى اي محتوى/ افلام اباحية
                </label>
            </div>
            <Divider />
            {/*Question Ten*/}
            <div className='pb-36 md:pb-16' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > هل تشعر ان قوة انتصابك تكون افضل خارج العلاقة الجنسية مع شريكك؟ (يمكنك اختيار اكثر من اجابة)
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionTen"
                    value="اشعر ان قوة انتصابي تكون افضل عند مشاهدة محتوى اباحي"
                    type="checkbox"
                    checked={checkedState[0].q1}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q1 === false) {
                                const newArray = [...questionTen];
                                newArray[0] = event.target.value;
                                setQuestionTen(newArray);
                                return { ...obj, q1: true };
                            } else if (obj.q1 === true) {
                                const newArray = [...questionTen];
                                newArray[0] = "Unchecked";
                                setQuestionTen(newArray);
                                return { ...obj, q1: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر ان قوة انتصابي تكون افضل عند مشاهدة محتوى اباحي
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input name="questionTen"
                    className='ml-2 mt-[5px] flex-none'
                    value="اشعر ان قوة انتصابي تكون افضل حين انجذب لشخص غير شريكي الحالي"
                    type="checkbox"
                    checked={checkedState[0].q2}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q2 === false) {
                                const newArray = [...questionTen];
                                newArray[1] = event.target.value;
                                setQuestionTen(newArray);
                                return { ...obj, q2: true };
                            } else if (obj.q2 === true) {
                                const newArray = [...questionTen];
                                newArray[1] = "Unchecked";
                                setQuestionTen(newArray);
                                return { ...obj, q2: false };
                            }
                            return obj;
                        }));

                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر ان قوة انتصابي تكون افضل حين انجذب لشخص غير شريكي الحالي
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input name="questionTen"
                    className='ml-2 mt-[5px] flex-none'
                    value="اشعر ان قوة انتصابي تكون افضل اثناء ممارسة العادة السرية"
                    type="checkbox"
                    checked={checkedState[0].q3}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q3 === false) {
                                const newArray = [...questionTen];
                                newArray[2] = event.target.value;
                                setQuestionTen(newArray);
                                return { ...obj, q3: true };
                            } else if (obj.q3 === true) {
                                const newArray = [...questionTen];
                                newArray[2] = "Unchecked";
                                setQuestionTen(newArray);
                                return { ...obj, q3: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> اشعر ان قوة انتصابي تكون افضل اثناء ممارسة العادة السرية
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input name="questionTen"
                    className='ml-2 mt-[5px] flex-none'
                    value="لا اشعر ان قوة انتصابي تختلف في العلاقة الجنسية مع شريكي او خارجها"
                    type="checkbox"
                    checked={checkedState[0].q4}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q4 === false) {
                                const newArray = [...questionTen];
                                newArray[3] = event.target.value;
                                setQuestionTen(newArray);
                                return { ...obj, q4: true };
                            } else if (obj.q4 === true) {
                                const newArray = [...questionTen];
                                newArray[3] = "Unchecked";
                                setQuestionTen(newArray);
                                return { ...obj, q4: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> لا اشعر ان قوة انتصابي تختلف في العلاقة الجنسية مع شريكي او خارجها
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input name="questionTen"
                    className='ml-2 mt-[5px] flex-none'
                    value="ليس لدي شريك/ لست متزوج"
                    type="checkbox"
                    checked={checkedState[0].q5}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q5 === false) {
                                const newArray = [...questionTen];
                                newArray[4] = event.target.value;
                                setQuestionTen(newArray);
                                return { ...obj, q5: true };
                            } else if (obj.q5 === true) {
                                const newArray = [...questionTen];
                                newArray[4] = "Unchecked";
                                setQuestionTen(newArray);
                                return { ...obj, q5: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> ليس لدي شريك/ لست متزوج
                </label>
            </div>
        </div >
    )
}

export default Step2