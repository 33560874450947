import React, { useState, useEffect } from 'react'
import Divider from './Divider'

const Step3 = ({ formData, setFormData, updateQuestionEleven, consent, setConsent, consent2, setConsent2 }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const [checkedState, setCheckedState] = useState([{
        q1: false,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false,
        q7: false,
    }]);

    const [questionEleven, setQuestionEleven] = useState([
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
        'Unchecked',
    ]);

    useEffect(() => {
        updateQuestionEleven(questionEleven);
    }, [questionEleven]);

    return (
        <div className='flex-col w-[70%] mr-[15%] ml-[15%]'>
            {/*Question Eleven*/}
            <div className='md:pb-16 pb-24' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > هل تعاني من اي من الامراض الاتية او تتلقي علاج لاي منها؟ (يمكنك اختيار اكثر من اجابة)
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="ارتفاع او انخفاض في ضغط الدم"
                    type="checkbox"
                    checked={checkedState[0].q1}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q1 === false) {
                                const newArray = [...questionEleven];
                                newArray[0] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q1: true };
                            } else if (obj.q1 === true) {
                                const newArray = [...questionEleven];
                                newArray[0] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q1: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> ارتفاع او انخفاض في ضغط الدم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="امراض القلب (عدم انتظام ضربات القلب؛ جلطات القلب او اخرى)"
                    type="checkbox"
                    checked={checkedState[0].q2}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q2 === false) {
                                const newArray = [...questionEleven];
                                newArray[1] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q2: true };
                            } else if (obj.q2 === true) {
                                const newArray = [...questionEleven];
                                newArray[1] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q2: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> امراض القلب (عدم انتظام ضربات القلب؛ جلطات القلب او اخرى)
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="السكر"
                    type="checkbox"
                    checked={checkedState[0].q3}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q3 === false) {
                                const newArray = [...questionEleven];
                                newArray[2] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q3: true };
                            } else if (obj.q3 === true) {
                                const newArray = [...questionEleven];
                                newArray[2] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q3: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> السكر
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="الكولسترول"
                    type="checkbox"
                    checked={checkedState[0].q4}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q4 === false) {
                                const newArray = [...questionEleven];
                                newArray[3] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q4: true };
                            } else if (obj.q4 === true) {
                                const newArray = [...questionEleven];
                                newArray[3] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q4: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> الكولسترول
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="امراض الكبد"
                    type="checkbox"
                    checked={checkedState[0].q5}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q5 === false) {
                                const newArray = [...questionEleven];
                                newArray[4] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q5: true };
                            } else if (obj.q5 === true) {
                                const newArray = [...questionEleven];
                                newArray[4] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q5: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> امراض الكبد
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="امراض الكلى"
                    type="checkbox"
                    checked={checkedState[0].q6}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q6 === false) {
                                const newArray = [...questionEleven];
                                newArray[5] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q6: true };
                            } else if (obj.q6 === true) {
                                const newArray = [...questionEleven];
                                newArray[5] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q6: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> امراض الكلى
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input
                    className='ml-2 mt-[5px] flex-none'
                    name="questionEleven"
                    value="لا اعاني من اي من الامراض المذكورة اعلاه"
                    type="checkbox"
                    checked={checkedState[0].q7}
                    onChange={(event) => {
                        setCheckedState(current => current.map(obj => {
                            if (obj.q7 === false) {
                                const newArray = [...questionEleven];
                                newArray[6] = event.target.value;
                                setQuestionEleven(newArray);
                                return { ...obj, q7: true };
                            } else if (obj.q7 === true) {
                                const newArray = [...questionEleven];
                                newArray[6] = "Unchecked";
                                setQuestionEleven(newArray);
                                return { ...obj, q7: false };
                            }
                            return obj;
                        }));
                    }}
                />
                <label className='font-CairoSBold' htmlFor="Check Me"> لا اعاني من اي من الامراض المذكورة اعلاه
                </label>
            </div>
            <Divider />
            {/*Question Twelve*/}
            <div className='pb-14' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > هل أنت مدخن؟
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="لا" name="questionTwelve"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionTwelve === "لا"} />
                <label className='font-CairoSBold' htmlFor=""> لا
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="نعم - أدخن أقل من علبة في الأسبوع/ أدخن حوالي حجر شيشة في الأسبوع" name="questionTwelve"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionTwelve === "نعم - أدخن أقل من علبة في الأسبوع/ أدخن حوالي حجر شيشة في الأسبوع"} />
                <label htmlFor="" className='font-CairoSBold'>نعم - أدخن أقل من علبة في الأسبوع/ أدخن حوالي حجر شيشة في الأسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="نعم - أدخن أقل من علبة في اليوم/ أدخن حوالي حجر شيشة في اليوم" name="questionTwelve" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionTwelve === "نعم - أدخن أقل من علبة في اليوم/ أدخن حوالي حجر شيشة في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'>نعم - أدخن أقل من علبة في اليوم/ أدخن حوالي حجر شيشة في اليوم
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="نعم - أدخن اكثر من علبة في اليوم/ أدخن أكثر من حجر شيشة في اليوم" name="questionTwelve" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionTwelve === "نعم - أدخن اكثر من علبة في اليوم/ أدخن أكثر من حجر شيشة في اليوم"} />
                <label htmlFor="" className='font-CairoSBold'>نعم - أدخن اكثر من علبة في اليوم/ أدخن أكثر من حجر شيشة في اليوم
                </label>
            </div>
            <Divider />
            {/*Question Thirteen*/}
            <div className='md:pb-28 pb-48' style={{ direction: 'rtl' }}>
                <label className='float-right font-AlmaraiBold text-xl' htmlFor="age">هل تتناول/ تستخدم اي وصفات طبيعية او وصفات طبية او ادوية لعلاج الضعف الجنسي او سرعة القذف؟ (مثال: فياجرا/ اريك/ جينسينج/ حقن؛ الرجاء كتابة جميع الادوية و الوصفات المستخدمة بما في ذلك الجرعة و عدد مرات الاستخدام)
                </label>
            </div>
            <div className='pt-20 pb-28'>
                <textarea
                    onChange={(event) => { setFormData({ ...formData, [event.target.name]: event.target.value }) }}
                    name="questionThirteen" rows="4" cols="50" className='float-right w-[90%] font-CairoRegular text-center rounded-md resize-none' placeholder='اجب هنا' />
            </div>
            <Divider />
            {/*Question Fourteen*/}
            <div className='pb-24 md:pb-16' style={{ direction: 'rtl' }}>
                < label className='float-right font-AlmaraiBold text-xl' htmlFor="age" > كم عدد المرات التي تتوقع ان تمارس فيها علاقة جنسية مع شريكك؟
                </label >
            </div >
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة واحدة في الشهر" name="questionFourteen"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }} checked={formData.questionFourteen === "مرة واحدة في الشهر"} />
                <label className='font-CairoSBold' htmlFor=""> مرة واحدة في الشهر
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }} >
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة واحدة كل اسبوعين" name="questionFourteen"
                    onChange={event => {
                        const target = event.target
                        const name = target.name;
                        const value = target.value
                        setFormData({
                            ...formData, [name]: value
                        })
                    }}
                    checked={formData.questionFourteen === "مرة واحدة كل اسبوعين"} />
                <label htmlFor="" className='font-CairoSBold pr-2'>مرة واحدة كل اسبوعين
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرة كل اسبوع" name="questionFourteen" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionFourteen === "مرة كل اسبوع"} />
                <label htmlFor="" className='font-CairoSBold pr-2'>مرة كل اسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="مرتين كل اسبوع" name="questionFourteen" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionFourteen === "مرتين كل اسبوع"} />
                <label htmlFor="" className='font-CairoSBold pr-2'>مرتين كل اسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="ثلاثة مرات كل اسبوع" name="questionFourteen" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionFourteen === "ثلاثة مرات كل اسبوع"} />
                <label htmlFor="" className='font-CairoSBold pr-2'> ثلاثة مرات كل اسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="اربع مرات او اكثر كل اسبوع" name="questionFourteen" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionFourteen === "اربع مرات او اكثر كل اسبوع"} />
                <label htmlFor="" className='font-CairoSBold pr-2'> اربع مرات او اكثر كل اسبوع
                </label>
            </div>
            <div className='ml-2 mb-2 flex items-start pb-10' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="radio" value="ليس لدي شريك/ لست متزوج بعد" name="questionFourteen" onChange={event => {
                    const target = event.target
                    const name = target.name;
                    const value = target.value
                    setFormData({
                        ...formData, [name]: value
                    })
                }} checked={formData.questionFourteen === "ليس لدي شريك/ لست متزوج بعد"} />
                <label htmlFor="" className='font-CairoSBold pr-2'> ليس لدي شريك/ لست متزوج بعد
                </label>
            </div>
            <Divider />
            <div className='pt-10 ml-2 mb-2 flex items-start pb-5' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="checkbox" name="consent" onChange={() => {
                    setConsent(!consent);
                }} />
                <label htmlFor="" className='font-CairoSBold pr-2  '> اصرح لـفيكتو  باستخدام البيانات المقدمة لأغراض التشخيص والبحث وفقًا للمبادئ العامة لاداب الخصوصية بين المرضى و الأطباء
                </label>
            </div>
            <div className='pt-2 ml-2 mb-2 flex items-start pb-10' style={{ direction: 'rtl' }}>
                <input className='ml-2 mt-[5px] flex-none' type="checkbox" name="consent2" onChange={() => {
                    setConsent2(!consent2);
                }} />
                <label htmlFor="" className='font-CairoSBold pr-2  '> اقر بصحة البيانات المقدمة فى هذا الاستبيان
                </label>
            </div>
        </div >
    )
}

export default Step3