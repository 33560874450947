import React from 'react'

const DesktopStep = (props) => {
    return (
        <div className='bg-primary-offwhite pl-5 pr-5 rounded-xl pt-[3.5%] pb-[3.5%] w-[80%] ml-[10%] mr-[10%] '>
            <div className='flex justify-evenly'>
                <div className='flex rounded-full justify-center  bg-primary-blue w-[10vw] h-[10vw]'>
                    <img className='w-[60%]' src={props.img} alt="img" />
                </div>
                <div className='flex-row'>
                    <h1 style={{ direction: "rtl" }} className='font-AlmaraiBold text-5xl text-primary-blue'> {props.title}</h1>
                    <h2 style={{ direction: "rtl" }} className="pt-5 font-CairoMedium text-xl">{props.body}</h2>
                </div>
            </div>
        </div>
    )
}

export default DesktopStep