import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Logout from '../assets/img/log-out.svg';


const Dashboard = () => {
    const navigate = useNavigate();
    const mystate = useLocation();
    const [loginState, setLoginState] = useState(mystate.state.state);
    const [privilege, setPrivilege] = useState(mystate.state.privilege);
    const [username, setUsername] = useState(mystate.state.username);

    useEffect(() => {
        const mystate2 = window.localStorage.setItem('VEKTU_SESSION', JSON.stringify(loginState))
        if (mystate2 === false) {
            navigate('/auth')
        }
    }, [mystate, loginState]);

    const logout = () => {
        setLoginState(!loginState);
        setPrivilege("");
        setUsername("");
        navigate('/auth')
        window.localStorage.setItem('VEKTU_SESSION', JSON.stringify(false))
        window.localStorage.setItem('VEKTU_PRIVILEGE', JSON.stringify(""));
    }

    const handleAddUsers = () => {
        navigate('/manageusers', { state: { privilege: privilege } });
    }

    const handleViewUsers = () => {
        navigate('/viewusers');
    }

    const handleViewMobiles = () => {
        navigate('/viewmobiles');
    }

    return (
        <div>
            {console.log("login Status " + loginState.state)}
            {console.log("login Status " + mystate)}
            <div>
                <div className='flex justify-between md:pt-2 pb-2'>
                    <h1 className='text-2xl md:text-4xl font-NeutralBold pt-5 pb-2 pl-3'>Welcome {username}</h1>
                    <img className='pr-5 pt-3  md:w-[70px] ' onClick={logout} src={Logout} alt="" />
                </div>
                <h2 className='text-sm md:text-base font-NeutralReg pl-3 pb-10'>what would you like to do today?</h2>
                {privilege === "admin" ?
                    <>
                        <div className='bg-primary-blue md:w-[50%] w-[80%] justify-center flex m-auto md:h-[400px] h-[300px] pt-5 rounded-lg' >
                            <button onClick={handleAddUsers} className='bg-primary-blue text-primary-offwhite text-3xl
                            font-NeutralBold'>
                                Add Users
                            </button>
                        </div>
                        <div className='bg-primary-blue md:w-[50%] w-[80%] justify-center flex m-auto md:h-[400px] h-[300px] pt-5 mt-10 rounded-lg mb-20'>
                            <button onClick={handleViewUsers} className='bg-primary-blue text-primary-offwhite text-3xl
                            font-NeutralBold'>
                                View Surveys
                            </button>
                        </div>
                        <div className='bg-primary-blue md:w-[50%] w-[80%] justify-center flex m-auto md:h-[400px] h-[300px] pt-5 mt-10 rounded-lg mb-20'>
                            <button onClick={handleViewMobiles} className="bg-primary-blue text-primary-offwhite text-3xl
                            font-NeutralBold">
                                View Mobiles
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className='bg-primary-blue md:w-[50%] w-[80%] justify-center flex m-auto md:h-[400px] h-[300px] pt-5 mt-10 rounded-lg mb-20'>
                            <button onClick={handleViewUsers} className='bg-primary-blue text-primary-offwhite text-3xl
                            font-NeutralBold'>
                                View Surveys
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Dashboard