import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Divider from '../components/Divider';

const SurveyAnswers = () => {
    const mystate = useLocation();
    const [questionTwo, setQuestionTwo] = useState(mystate.state.userData);
    const [surveyAnswers, setSurveyAnswers] = useState([]);

    useEffect(() => {
        retrieveUsers(questionTwo);
    }, [questionTwo]);

    const retrieveUsers = async (questionTwo) => {
        await axios.get("https://vektu.herokuapp.com/userAnswers", {
            params: {
                questionTwo: questionTwo
            }
        }).then((response) => {
            if (response.data.length === 0) {
                console.log("No Data Found!");
            } else {
                console.log(response);
                setSurveyAnswers(response.data);
            }
        });
    }

    return (
        <div>
            <h1 className='text-2xl font-NeutralBold pb-7 pl-3 pt-5'>Survey Answers</h1>
            <div>
                {surveyAnswers.map((val) => {
                    return <div className='' key={val._id}>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"السن"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionOne}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"ما هو رقم الهاتف الذي يمكننا التواصل معك من خلاله؟ (الرجاء استخدام رقم هاتف مسجل على تطبيق واتساب ليتمكن فريقنا من التواصل معك)"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionTwo}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"ما هو السبب الرئيسي لزيارة موقع فيكتو اليوم؟ (يمكنك اختيار اكثر من اجابة)"}
                            </h1>
                            <div className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionThree.map((obj) => {
                                    return <h1>{obj}</h1>
                                })}
                            </div>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"كم مرة في الاسبوع تقريبا تشعر بانتصاب عند الاستيقاظ من النوم؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionFour}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"كم مرة في اليوم تقريبا تشعر بانتصاب؟ (الرجاء ان تتضمن عدد المرات اثناء اداء العمل او الروتين اليومي و ليس اثناء التعرض الى او مشاهدة اي محتوى/ موقف اغرائي او اباحي)"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionFive}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل تتطلع لممارسة العلاقة الجنسية مع شريكك و تستمتع بهذه العلاقة؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionSix}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"متى كانت المرة الاخيرة التي شعرت فيها بانتصاب يرضيك دون استخدام اي منشطات او ادوية؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionSeven}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل تشعر بألم اثناء الانتصاب او العلاقة الجنسية او التبول؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionEight}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"كم مرة تقريبا تشاهد/ تستمع الى اي محتوى/ افلام إباحية؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionNine}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل تشعر ان قوة انتصابك تكون افضل خارج العلاقة الجنسية مع شريكك؟ (يمكنك اختيار اكثر من اجابة)"}
                            </h1>
                            <div className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionTen.map((obj) => {
                                    return <h1>{obj}</h1>
                                })}
                            </div>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل تعاني من اي من الامراض الاتية او تتلقي علاج لاي منها؟ (يمكنك اختيار اكثر من اجابة)"}
                            </h1>
                            <div className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionEleven.map((obj) => {
                                    return <h1>{obj}</h1>
                                })}
                            </div>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل أنت مدخن؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionTwelve}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"هل تتناول/ تستخدم اي وصفات طبيعية او وصفات طبية او ادوية لعلاج الضعف الجنسي او سرعة القذف؟ (مثال: فياجرا/ اريك/ جينسينج/ حقن؛ الرجاء كتابة جميع الادوية و الوصفات المستخدمة بما في ذلك الجرعة و عدد مرات الاستخدام)"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionThirteen}
                            </h2>
                            <Divider />
                        </div>
                        <div>
                            <h1 className='font-CairoMedium pl-5 md:text-3xl'>
                                {"كم عدد المرات التي تتوقع ان تمارس فيها علاقة جنسية مع شريكك؟"}
                            </h1>
                            <h2 className=' font-AlmaraiBold pl-5 pt-6 md:text-3xl'>
                                {val.questionFourteen}
                            </h2>
                            <Divider />
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default SurveyAnswers