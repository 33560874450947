import React, { useState } from 'react'
import Illustration from '../assets/img/illustration.svg';
import Modal from './Modal';

const Hero = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(prev => !prev)
    }

    return (
        <section id="home" className='flex w-full pt-6 pb-10'>
            <div className='hidden lg:flex lg:w-[40] '>
                <img src={Illustration} alt="" />
            </div>
            <div className=' lg:w-[65%] justify-center text-center w-[100%] ml-[7%] lg:ml-[0%] mt-10 mr-[7%]'>
                <h1 style={{ direction: 'rtl' }} className=' leading-[50px] lg:leading-[60px] flex-col mb-10 font-AlmaraiEXBold text-4xl lg:text-5xl text-center lg:text-right'>
                    <span className='text-primary-blue'>
                        64% &nbsp;
                    </span>
                    من الرجال
                    يواجهون شكلا من أشكال
                    المعاناة فى الأداء الجنسي
                </h1>
                <h2 style={{ direction: 'rtl' }} className='flex-col mb-5 font-CairoSBold text-2xl text-center lg:text-right'>
                    <span className='font-CairoEXBold text-primary-blue'>
                        &nbsp; VEKTU &nbsp;
                    </span>
                    هنا للمساعدة
                </h2>
                <button onClick={openModal}
                    className="bg-primary-blue lg:w-[70%] lg:float-right w-[70%] h-16 rounded-full text-primary-offwhite font-CairoBlack mt-5 outline-none">تواصل مع أحد خبرائنا الآن</button>
            </div>
            <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} toggleModal={openModal} />
        </section>
    )
}

export default Hero