import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ViewUsers = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelecetedUser] = useState("init");

    useEffect(() => {
        retrieveUsers();
        console.log(selectedUser);
        if (selectedUser !== "init") {
            navigate('/surveyanswers', { state: { userData: selectedUser } });
        }
    }, [selectedUser])

    const handleUserNavigation = event => {
        setSelecetedUser(event.target.value);
    }

    const retrieveUsers = async () => {
        await axios.get("https://vektu.herokuapp.com/usersPhones", {
        }).then((response) => {
            if (response.data.length === 0) {
                console.log("No Data Found!");
            } else {
                console.log(response);
                setUsers(response.data);
            }
        });
    }

    return (
        <>
            <h1 className='text-2xl font-NeutralBold pb-2 pl-3 pt-5'>Survey Answers</h1>
            <div className='flex justify-center pb-10 pt-3'>
                <input className='rounded-full px-2 py-2 w-[90%] m-auto'
                    placeholder='Search'
                    type="text" name="" id="" />
            </div>
            {users.map((val) => {
                return <div className='bg-primary-blue w-[80%] pb-3 pt-3 m-auto mb-10
                font-NeutralReg text-center rounded-lg text-primary-offwhite'>
                    <button className='block m-auto'
                        onClick={handleUserNavigation}
                        value={val.questionTwo}>{"User " + val.questionTwo}
                    </button>
                </div>
            })}
        </>
    )
}

export default ViewUsers