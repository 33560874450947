import React from 'react'
import { Outlet } from 'react-router-dom'
import Auth from './Auth'

const ProtectedRoutes = (props) => {
    const useAuth = () => {
        const loggedIn = props.state;
        return loggedIn;
    }

    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Auth />
}

export default ProtectedRoutes